import React, { useState, useEffect } from 'react'
import sanityClient from '../client'

import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'

const Navbar = () => {
  const [navLinks, setNavLinks] = useState(null)
  const [mobileMenu, setMobileMenu] = useState(false)

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "navLinks"]|order(order asc){
      title,
      link
    }`
      )
      .then((data) => setNavLinks(data))
      .catch(console.error);
  }, []);

  if (!navLinks) {
    return (
      <h1>Loading...</h1>
    )
  }

  return (
    <nav className='absolute top-0 w-[100%] flex p-6 justify-center gap-12 z-10'>
      <div className="navLinks sm:flex hidden justify-center gap-12">
        {navLinks.map((link, index) => (
          <a className='text-2xl capitalize hover:opacity-80 font-head transition-all' key={link + index} href={link.link}>{link.title}</a>
        ))}
      </div>

      <AiOutlineMenu className='sm:hidden flex cursor-pointer w-[10mm] h-[10mm] absolute top-5 right-5' onClick={() => setMobileMenu(true)} />

      <div className={`mobile-menu p-5 flex justify-center items-center sm:hidden fixed top-0 left-0 w-[100dvw] h-[100dvh] bg-[#080808] z-[2] ${mobileMenu ? '' : 'hidden'}`}>
        <AiOutlineClose className='absolute top-5 right-5 w-[10mm] h-[10mm] cursor-pointer' onClick={() => setMobileMenu(false)} />

        <div className="navLinks flex flex-col text-center justify-center gap-6">
          {navLinks.map((link, index) => (
            <a className='text-5xl script fancy-hover' key={link + index} href={link.link} onClick={() => setMobileMenu(false)}>{link.title}</a>
          ))}
        </div>
      </div>
    </nav>
  )
}

export default Navbar