import React from 'react'
import { note1, note2 } from '../assets'

const SectionTitle = ({ title, reverse, extraClass, iconsFalse }) => {
  return (
    <h2 className={`w-[100%] ${extraClass === 'text-black' ? 'text-black' : 'text-white'} font-head flex ${extraClass} ${reverse ? 'flex-row-reverse' : 'flex-row'} justify-center pb-20 pt-10 script text-black sm:text-5xl text-4xl text-center items-center gap-5`}>
        <img src={note1} alt="note1" className={`h-[65px] object-contain ${iconsFalse ? 'hidden' : 'flex'}`} />
        {title}
        <img src={note2} alt="note2" className={`h-[50px] object-contain ${iconsFalse ? 'hidden' : 'flex'}`} />
    </h2>
  )
}

export default SectionTitle