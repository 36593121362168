import React, { useState, useEffect } from "react";
import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";

import { Navbar, SocialIcons } from "../components";
import { PortableText } from "@portabletext/react";

const Header = () => {
  const builder = imageUrlBuilder(sanityClient);
  const [headerSection, setHeaderSection] = useState(null);

  function urlFor(source) {
    return builder.image(source);
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "header"]|order(orderRank){
      title,
      para,
      bgImgs,
      btn,
      bg
    }`
      )
      .then((data) => setHeaderSection(data))
      .catch(console.error);
  }, []);

  if (headerSection) {
    var header = headerSection[0];
  } else {
    return <h1>Loading...</h1>;
  }

  return (
    <section
      id="home"
      className="text-white w-full bg-black min-h-[100dvh] flex justify-center items-center flex-col relative px-6"
    >
      <Navbar />

      <div className="h-[100%] w-[100%] flex flex-col justify-center items-center max-w-[800px] text-center z-[1]">
        <img src={urlFor(header.title)} alt="logo" className="w-[500px] sm:pb-8" />
        <p className="mb-8 text-xl">
          <PortableText value={header.para} />
        </p>
        <a
          href={header.btn.btnLink}
          className="primary-button text-xl capitalize header-button"
        >
          <span>{header.btn.text}</span>
        </a>
        <a
          href="https://dancestudio-pro.com/online/songsquad"
          target="_blank"
          rel="noreferrer"
          className="text-xl primary-button header-button mt-4"
        >
          <span>Visit our online booking portal</span>
        </a>

        <SocialIcons />
      </div>

      <img
        src={urlFor(header.bg)}
        alt="background"
        className="background absolute top-0 left-0 w-full h-full object-cover opacity-30 md:opacity-25 pointer-events-none"
      />
    </section>
  );
};

export default Header;
