import React from "react";
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";

const SocialIcons = () => {
  return (
    <div className="fixed flex flex-col left-0 bottom-[10%] bg-[#191919] p-3 gap-3 rounded-r-lg">
      <a
        href="https://www.instagram.com/thesongsquad/"
        className="w-[30px] h-[30px]"
        target="_blank"
        rel="noreferrer"
      >
        <AiOutlineInstagram className="w-full h-full" />
      </a>
      <a
        href="https://www.facebook.com/TheSongSquad"
        className="w-[30px] h-[30px]"
        target="_blank"
        rel="noreferrer"
      >
        <FaFacebookF className="w-full h-full" />
      </a>
      <a
        href="https://twitter.com/SongSquadUK"
        className="w-[30px] h-[30px]"
        target="_blank"
        rel="noreferrer"
      >
        <AiOutlineTwitter className="w-full h-full" />
      </a>
    </div>
  );
};

export default SocialIcons;
