import React from 'react'

import { AiOutlineInstagram, AiOutlineTwitter } from 'react-icons/ai'
import { FaFacebookF } from 'react-icons/fa'

const Footer = () => {
  return (
    <footer className='p-6 text-white text-md bg-black w-full flex flex-col justify-center items-center'>
        <div className="links flex items-center justify-center gap-3 mb-2">
            <a href="https://www.instagram.com/thesongsquad/" className='w-[30px] h-[30px]' target='_blank' rel='noreferrer'><AiOutlineInstagram className='w-full h-full' /></a>
            <a href="https://www.facebook.com/TheSongSquad" className='w-[30px] h-[30px]' target='_blank' rel='noreferrer'><FaFacebookF className='w-full h-full' /></a>
            <a href="https://twitter.com/SongSquadUK" className='w-[30px] h-[30px]' target='_blank' rel='noreferrer'><AiOutlineTwitter className='w-full h-full' /></a>
        </div>

        <p className='text-xl'>Made by <a className='fancy-hover' href="http://thomasprice.me/Portfolio/" target='_blank' rel='noreferrer'>Thomas Price</a></p>

        <a className="primary-button header-button mt-4 text-xl" target='_blank' rel='noreferrer' href='https://dancestudio-pro.com/online/songsquad'><span>Access Our Online Booking Portal</span></a>
    </footer>
  )
}

export default Footer