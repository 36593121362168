import sanityClient from "../client";
import { useState, useEffect } from "react";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";

const ClassList = () => {
  const [classes, setClasses] = useState(null);
  const builder = imageUrlBuilder(sanityClient);

  function urlFor(source) {
    return builder.image(source);
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "classes"]|order(order){
          name,
          desc,
          logo
        }`
      )
      .then((data) => setClasses(data))
      .catch(console.error);
  }, []);

  if (classes) {
  } else {
    return <h1>Loading...</h1>;
  }

  return (
    <div className="text-lg text-center mb-10 p-6 flex flex-col justify-center items-stretch sm:flex-row flex-wrap gap-16 sm:gap-4 w-[100vw] container">
      {classes.map((item, index) => (
        <div className="rainbow-border" key={item + index}>
          <div className="flex group p-4 h-[100%] bg-[#f5f5f5] relative flex-col w-full sm:w-[300px] items-center justify-start gap-2 transition-all duration-300">
            {/* IMAGE */}
            <div className="h-[200px] transition-all duration-500 ease-in-out w-full mt-2 absolute top-[50%] translate-y-[-50%] group-hover:relative group-hover:top-0 group-hover:translate-y-0">
              {item.logo ? (
                <img
                  src={urlFor(item.logo)}
                  alt={item.name}
                  className="w-full object-contain h-[200px] px-2"
                />
              ) : (
                <div className="h-[200px]"></div>
              )}
            </div>
            <div className="flex gap-2 flex-col ease-in mt-[200px] group-hover:mt-0">
              <h4 className="font-head text-2xl opacity-0 group-hover:opacity-100 transition-all duration-500 delay-150">
                {item.name}
              </h4>
              <p className="opacity-0 group-hover:opacity-100 transition-all duration-500 delay-150 text-xl">
                <PortableText value={item.desc} />
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClassList;
