import React, { useState, useEffect } from "react";

import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";

import { SectionTitle } from "../components";
import { PortableText } from "@portabletext/react";

const About = () => {
  const builder = imageUrlBuilder(sanityClient);
  const [aboutSection, setAboutSection] = useState(null);

  function urlFor(source) {
    return builder.image(source);
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "about"]|order(orderRank){
      text,
      title,
      img,
      btn
    }`
      )
      .then((data) => setAboutSection(data))
      .catch(console.error);
  }, []);

  if (aboutSection) {
    var about = aboutSection[0];
  } else {
    return <h1>Loading...</h1>;
  }

  return (
    <section id="about" className="w-full relative bg-black">
      <div className="container px-6 mx-auto mb-20 relative z-10">
        <SectionTitle title="About Us" reverse={false} />

        <div className="content flex items-center justify-center flex-col sm:flex-row gap-[60px]">
          <div className="left flex-1 flex flex-col items-center sm:items-start">
            <p className="mb-10 text-xl">
              <PortableText value={about.text} />
            </p>
            <a
              href={about.btn.link}
              className="text-xl header-button capitalize primary-button "
            >
              <span>{about.btn.text}</span>
            </a>
            <a
              href="https://dancestudio-pro.com/online/songsquad"
              target="_blank"
              rel="noreferrer"
              className="text-xl header-button primary-button mt-4 "
            >
              <span>Book Now for a FREE Trial Session</span>
            </a>
          </div>

          <div className="flex-1 "></div>
        </div>
      </div>

      <img
        src={urlFor(about.img)}
        alt="background"
        className="opacity-25 w-full h-full object-cover absolute top-0 left-0 z-0"
      />
    </section>
  );
};

export default About;
