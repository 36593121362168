import React from "react";
import { SectionTitle, GalleryComponent } from "../components";

const Gallery = () => {
  return (
    <section id="gallery" className="w-full bg-black">
      <div className="container px-6 mx-auto mb-20">
        <SectionTitle title="Gallery" reverse={true} />
        <GalleryComponent />
      </div>
    </section>
  );
};

export default Gallery;
