import { Header, About, Classes, Teachers, Gallery, Contact } from '../containers'
import { Footer } from '../components'

const Home = () => {
  return (
    <main className='flex flex-col text-white items-center overflow-hidden font-text'>
        <Header />
        <About />
        <Classes />
        <Teachers />
        <Gallery />
        <Contact />
        <Footer />
    </main>
  )
}

export default Home