import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Safeguarding from "./pages/Safeguarding"
import Home from "./pages/Home"

import './App.css'

import emailjs from '@emailjs/browser';

emailjs.init({
  publicKey: "BCK6ynfq2y7qWWEXm",
});

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/policies' element={<Safeguarding />} />
      </Routes>
    </Router>
  )
}

export default App