import React, { useState, useEffect } from "react";
import { SectionTitle } from "../components";

import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";
import { PortableText } from "@portabletext/react";
import { teacherBg } from "../assets";

const Teachers = () => {
  const builder = imageUrlBuilder(sanityClient);
  const [teachers, setTeachers] = useState(null);

  function urlFor(source) {
    return builder.image(source);
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "teachers"]|order(order asc){
      name,
      img,
      desc,
    }`
      )
      .then((data) => setTeachers(data))
      .catch(console.error);
  }, []);

  if (!teachers) {
    return <h1>Loading...</h1>;
  }

  return (
    <section id="teachers" className="w-full bg-black relative">
      <div className="mx-auto container px-6 mb-20 relative z-10">
        <SectionTitle title="Our Teachers" reverse={false} />

        <div className="teachers flex flex-col items-center justify-center gap-12">
          {teachers.map((teacher, index) => (
            <div
              key={teacher + index}
              className={`teacher flex flex-col sm:flex-row items-center max-w-[1200px] justify-center ${
                index % 2 === 0 ? "flex-row" : "flex-row-reverse"
              } gap-6 hover:scale-[1.02] transition-all duration-300`}
            >
              <img
                src={urlFor(teacher.img)}
                alt="profile"
                className="rounded-full max-w-[200px]"
              />
              <div>
                <p className="mt-4 font-head text-2xl">{teacher.name}</p>
                <p className="mt-2 text-xl">
                  <PortableText value={teacher.desc} />
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <img
        src={teacherBg}
        alt="background"
        className="opacity-25 w-full h-full object-cover absolute top-0 left-0 z-0"
      />
    </section>
  );
};

export default Teachers;
