import React, { useState, useEffect } from "react";

import sanityClient from "../client";
import imageUrlBuilder from "@sanity/image-url";

import { ContactForm, SectionTitle } from "../components";

const Contact = () => {
  const builder = imageUrlBuilder(sanityClient);
  const [contactSection, setContactSection] = useState(null);

  function urlFor(source) {
    return builder.image(source);
  }

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "contact"]|order(orderRank){
      para,
      img,
    }`
      )
      .then((data) => setContactSection(data))
      .catch(console.error);
  }, []);

  if (contactSection) {
    var contact = contactSection[0];
  } else {
    return <h1>Loading...</h1>;
  }

  return (
    <section id="contact" className="w-full relative bg-black">
      <div className="container px-6 mx-auto mb-20 relative z-10">
        <SectionTitle title="Contact Us" reverse={false} />

        <div className="content flex items-center justify-center flex-col sm:flex-row gap-[60px]">
          <div className="left flex-1 flex flex-col items-start w-full">
            <p className="mb-10 text-xl">{contact.para}</p>
            <ContactForm />
            <a
              href="https://dancestudio-pro.com/online/songsquad"
              target="_blank"
              rel="noreferrer"
              className="text-xl header-button primary-button mt-4"
            >
              <span>Book Now for a FREE Trial Session</span>
            </a>
          </div>

          <div className="right flex-1"></div>
        </div>
      </div>

      <img
        src={urlFor(contact.img)}
        alt="background"
        className="opacity-25 w-full h-full object-cover absolute top-0 left-0 z-0"
      />
    </section>
  );
};

export default Contact;
