import { useEffect, useState } from "react";
import { SectionTitle } from "../components"

import sanityClient from "../client";
import { policyImage } from "../assets";

const Safeguarding = () => {
  const [policies, setPolicies] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "policy"] {
          title,
          "fileURL":pdf.asset->url
        }`
      )
      .then((data) => setPolicies(data))
      .catch(console.error);
  }, []);

  if (policies) {
  } else {
    return <h1>Loading...</h1>;
  }

  console.log(policies);

  return (
    <main className="flex bg-black flex-col min-h-screen text-white z-[1] items-center overflow-hidden font-text">
      <SectionTitle title={'Policies'} iconsFalse extraClass='pb-4 z-[1]' />
      <a href="/" className="text-xl opacity-80 z-[1]">BACK</a>

      <p className="text-2xl mt-4 z-[1]">Click for information about each policy</p>

      <ul className="mt-8 flex flex-col items-center z-[1]">
        {policies.map((policy, index) => (
          <li key={policy + index}>
            <a
              href={policy.fileURL}
              target="_blank"
              rel="noreferrer"
              className="text-xl primary-button header-button mt-4"
            >
              <span>{policy.title}</span>
            </a>
          </li>
        ))}
      </ul>

      <img src={policyImage} alt="background"
        className="background absolute top-0 left-0 w-full h-full object-cover opacity-30 md:opacity-25 pointer-events-none"/>
    </main>
  )
}

export default Safeguarding