import React, { useState, useEffect } from 'react'

import sanityClient from '../client'

import ImageGallery from 'react-image-gallery';

const GalleryComponent = () => {
    const [galleryImages, setGalleryImages] = useState(null)

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "gallery"]|order(orderRank){
          "imageUrl": img.asset->url
        }`
            )
            .then((data) => setGalleryImages(data))
            .catch(console.error);
    }, []);

    if (!galleryImages) {
        return (
            <h1>Loading...</h1>
        )
    }

    var images = [];
    galleryImages.map((image) => {
        images.push({
            original: image.imageUrl,
            thumbnail: image.imageUrl
        })

        return images
    })

    return <ImageGallery
        items={images}
        showPlayButton={false}
    />;
}

export default GalleryComponent