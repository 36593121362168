import React, { useState, useEffect } from "react";

import sanityClient from "../client";
import { Player } from "video-react";

import { SectionTitle } from "../components";
import ClassList from "../components/ClassList";
import { PortableText } from "@portabletext/react";

const Classes = () => {
  const [classesSection, setClassesSection] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "classesSection"]|order(orderRank){
      para,
      "videoUrl": video.asset->url
    }`
      )
      .then((data) => setClassesSection(data))
      .catch(console.error);
  }, []);

  if (classesSection) {
    var classSect = classesSection[0];
  } else {
    return <h1>Loading...</h1>;
  }

  return (
    <section id="classes" className="bg-[#f5f5f5] w-full">
      <div className="container px-6 flex mx-auto items-center flex-col mb-20">
        <SectionTitle title="Our Classes" reverse={true} extraClass={'text-black'} />

        <div className="video max-w-[800px] flex flex-col items-center text-black">
          <p className="text-xl text-center mb-10 px-6">
            <PortableText value={classSect.para} />
          </p>

          <p className="text-2xl text-center mb-10 md:hidden">
            Click to find out about each class!
          </p>
          <p className="text-2xl text-center mb-10 hidden md:flex">
            Hover to find out about each class!
          </p>
          <ClassList />

          <p className="text-2xl text-center mb-10 px-6">
            Watch the video to find out more about our classes:
          </p>
          <Player>
            <source src={classSect.videoUrl} />
          </Player>

          <a
            href="https://dancestudio-pro.com/online/songsquad"
            target="_blank"
            rel="noreferrer"
            className="text-xl primary-button header-button mt-8"
          >
            <span>Book Now for a FREE Trial Session</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Classes;
