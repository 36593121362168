import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';

const ContactForm = () => {

    const form = useRef()

    function sendEmail(e) {
        e.preventDefault();    //This is important, i'm not sure why, but the email won't send without it

        emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE, process.env.REACT_APP_EMAIL_TEMPLATE, form.current, process.env.REACT_APP_EMAIL_KEY)
            .then((result) => {
                window.location.reload()  //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior) 
            }, (error) => {
                console.log(error.text);
            });
    }

    return (
        <form onSubmit={sendEmail} ref={form} className='flex text-black flex-col w-[100%] gap-3 items-start'>
            <div className="names flex sm:flex-row flex-col w-full sm:gap-8 gap-3">
                <label className='sm:w-[50%] w-[100%]'>
                    <p className='text-white'>First name <span className='text-red-600'>*</span></p>
                    <input type="string" placeholder='John' required name='first_name' className='' />
                </label>
                <label className='sm:w-[50%] w-[100%]'>
                <p className='text-white'>Last name <span className='text-red-600'>*</span></p>
                    <input type="string" placeholder='Smith' required name='last_name' />
                </label>
            </div>

            <div className="email w-full">
                <label className=''>
                <p className='text-white'>Email address <span className='text-red-600'>*</span></p>
                    <input type="email" placeholder='example@corp.com' required name='reply_to' />
                </label>
            </div>

            <div className="message w-full">
                <label className='text-white'>
                    Your message
                    <textarea placeholder='Start typing...' rows={4} name='message' className='text-black' />
                </label>
            </div>

            <button type="submit" className='text-xl primary-button header-button'><span>Send it our way!</span></button>
        </form>
    )
}

export default ContactForm